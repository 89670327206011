<template>
  <nav class="pagination">
    <div class="pagination__inner">
      <a
        v-if="hasArrows && currentPage > 1"
        class="pagination__prev pagination__arrow pagination__item"
        aria-label="Go to previous page"
        @click.prevent="changePageMethod(currentPage - 1)"
        :href="route.path + '?p=' + (currentPage - 1)"
      >
        <span>
          <div class="icon-arrow-left"></div>
        </span>
      </a>
      <a
        v-if="showFirst"
        @click.prevent="changePageMethod(1)"
        :href="route.path + '?p=1'"
        class="pagination__first pagination__item"
      >
        <span>1</span>
      </a>
      <div v-if="firstVisiblePageNumber > 2" name="points">
        <div class="pagination__item pagination__item--small">
          <span>...</span>
        </div>
      </div>

      <div
        v-for="page in limitedPageNumbers"
        :key="page"
        class="pagination__number"
      >
        <a
          class="pagination__item"
          :class="[currentPage === page ? 'pagination__item--current' : '']"
          @click.prevent="changePageMethod(page)"
          :href="route.path + '?p=' + page"
        >
          <span>
            {{ page }}
          </span>
        </a>
      </div>

      <div v-if="lastVisiblePageNumber < total - 1" name="points">
        <div class="pagination__item pagination__item--small">
          <span>...</span>
        </div>
      </div>
      <a
        class="pagination__last pagination__item"
        v-if="showLast"
        @click.prevent="changePageMethod(total)"
        :href="route.path + '?p=' + total"
      >
        <span>
          {{ total }}
        </span>
      </a>

      <a
        v-if="hasArrows && currentPage < total"
        class="pagination__next pagination__arrow pagination__item"
        aria-label="Go to previous next"
        @click.prevent="changePageMethod(currentPage + 1)"
        :href="route.path + '?p=' + (currentPage + 1)"
      >
        <span>
          <div class="icon-arrow-right"></div>
        </span>
      </a>
    </div>
  </nav>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();
const router = useRouter();
const route = useRoute();
const app = useNuxtApp();

const props = defineProps({
  total: {
    type: Number,
    default: 0,
  },

  visible: {
    type: Number,
    default: 5,
  },

  hasArrows: {
    type: Boolean,
    default: true,
  },

  current: {
    type: Number,
    default: 1,
  },

  pageParamName: {
    type: String,
    default: "page",
  },
  brandSlug: {
    type: String,
    default: null,
  },
  isCustomListing: {
    type: Boolean,
    default: false,
  },
  noListing: {
    type: Boolean,
    default: false,
  },
});

const { getListing, changePage } = useListing({ brandSlug: props.brandSlug });

const changePageMethod = (pageNumber) => {
  if (props.noListing) {
    app.$bus.$emit("customSearchPagination", pageNumber, null);
    return;
  }

  changePage(pageNumber);
};

const currentListing = computed(() => {
  return store.currentListing;
});
const showFirst = computed(() => {
  return firstVisiblePageNumber.value > 1;
});
const showLast = computed(() => {
  return props.lastVisiblePageNumber < props.total;
});
const firstVisiblePageNumber = computed(() => {
  return limitedPageNumbers.value[0];
});
const lastVisiblePageNumber = computed(() => {
  return limitedPageNumbers.value[limitedPageNumbers.value.length - 1];
});
const listOfPageNumbers = computed(() => {
  return Array.from(Array(props.total), (_, i) => i + 1);
});
const limitedPageNumbers = computed(() => {
  if (props.total <= props.visible) {
    return listOfPageNumbers.value;
  }
  if (currentPage.value < props.visible - Math.floor(props.visible / 2) + 1) {
    return listOfPageNumbers.value.slice(0, props.visible);
  }
  if (
    props.total - currentPage.value <
    props.visible - Math.ceil(props.visible / 2) + 1
  ) {
    return listOfPageNumbers.value.slice(props.total - props.visible);
  }
  return listOfPageNumbers.value.slice(
    currentPage.value - Math.ceil(props.visible / 2),
    currentPage.value + Math.floor(props.visible / 2)
  );
});
const currentPage = computed(() => {
  if (props.current > 1) {
    return props.current;
  } else {
    return 1;
  }
});
</script>

<style lang="scss">
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $spacing-md;
  padding-left: $spacing-xs;
  padding-right: $spacing-xs;
}

.pagination__inner {
  display: flex;
  align-items: center;
}

.pagination__item {
  padding: $spacing-xxs;
  width: 2rem;
  height: 2rem;
  position: relative;
  border-radius: $radius-small;
  font-size: $text-sm;
  cursor: pointer;
  display: block;
  line-height: 1;

  &--small {
    width: $spacing-base;
    padding: 0;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &--current {
    background: $color-red;
    color: $color-white;
    font-weight: $font-bold;
  }
}

.pagination__arrow {
  font-size: $text-xl;
}
</style>
